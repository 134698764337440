.gallery-container {
  --bg-image: url(../Utils/Assests/Price.jpg);
  --bg-image-opacity: 0.5;
  position: relative;
  isolation: isolate;
  width: 100%;
  text-decoration: none;
}

.gallery-container::after {
  content: "";
  opacity: var(--bg-image-opacity);
  background-image: var(--bg-image);
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: -1;
  inset: 0;
}

.gallery {
  padding: 0 12px;
}

.gallery .media {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 16px;
}

.gallery .media:hover {
  filter: opacity(0.9);
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 1000;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.showInput {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 1000;
}

.showInput.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}



/* @media (max-width: 991px) {
    .gallery {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }
  
  @media (max-width: 800px) {
    .gallery {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-width: 50%;
      -moz-column-width: 50%;
      column-width: 50%;
    }
  } */
